import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatErrorMessagesComponent, MatRangeInputComponent } from '@shared/components';
import { FormResetDirective, TrimInputDirective, ValidSubmitDirective } from '@shared/directives';
import { CommonValidators } from 'app/core/validators/common.validators';

@Component({
  selector: 'app-cp-field',
  imports: [
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    FormResetDirective,
    MatRangeInputComponent,
    ValidSubmitDirective,
    MatFormField,
    MatInput,
    MatError,
    MatLabel,
    MatErrorMessagesComponent,
    TrimInputDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="form" appValidSubmit (onSubmit)="submit()" class="grid grid-cols-1 gap-y-2">
      <mat-form-field class="col-span-full">
        <mat-label>Name</mat-label>
        <input appTrimInput matInput formControlName="name" />
        <mat-error appMatErrorMessages></mat-error>
      </mat-form-field>
      <app-mat-range-input class="col-span-full" formGroupName="deposit" [useEnabler]="false" />
      <app-mat-range-input class="col-span-full" formGroupName="withdraw" />
      <button mat-flat-button color="warn" [appFormReset]="form">Reset</button>
      <button mat-flat-button color="primary">Submit</button>
    </form>
  `,
  styles: ``,
})
export class CpMatRangeInputComponent implements OnInit {
  form!: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl('', [CommonValidators.required]),
      deposit: new FormGroup(
        {
          min: new FormControl<number | null>(null, [
            CommonValidators.required,
            CommonValidators.min(0),
            CommonValidators.max(60000),
          ]),
          max: new FormControl<number | null>(null, [
            CommonValidators.required,
            CommonValidators.min(0),
            CommonValidators.max(65000),
          ]),
        },
        { validators: CommonValidators.minMaxValidator() },
      ),
      withdraw: new FormGroup(
        {
          enable: new FormControl<boolean>(true),
          min: new FormControl<number | null>(null, [
            CommonValidators.required,
            CommonValidators.min(0),
            CommonValidators.max(60000),
          ]),
          max: new FormControl<number | null>(null, [
            CommonValidators.required,
            CommonValidators.min(0),
            CommonValidators.max(65000),
          ]),
        },
        { validators: CommonValidators.minMaxValidator() },
      ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submit() {}
}
