import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonValidators } from '@core/validators';
import { FileUploaderComponent } from '@shared/components';
import { ValidSubmitDirective } from '@shared/directives';

@Component({
  selector: 'app-cp-upload-file',
  imports: [
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    JsonPipe,
    ValidSubmitDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="form" appValidSubmit (onSubmit)="onSubmit()">
      <app-file-uploader controlName="file1" required autoUpload></app-file-uploader>
      <app-file-uploader
        controlName="file2"
        [acceptedFormats]="['.jpg', '.png', '.svg', '.ico']"
      ></app-file-uploader>
      <app-file-uploader controlName="file3" humanizedMaxFileSize="256KB"></app-file-uploader>
      <app-file-uploader
        controlName="file4"
        dragDropMessage="drop any file you want in drop zone"
        chooseFileMessage="Select File"
      ></app-file-uploader>
      <app-file-uploader
        controlName="file5"
        humanizedMaxFileSize="1MB"
        humanizedMinFileSize="5KB"
      ></app-file-uploader>
      <button mat-flat-button color="primary">Submit</button>
    </form>

    <p>{{ form.value | json }}</p>
  `,
  styles: ``,
})
export class CpUploadFileComponent implements OnInit {
  form!: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      file1: new FormControl(null, CommonValidators.required),
      file2: new FormControl(null),
      file3: new FormControl(null),
      file4: new FormControl(null),
      file5: new FormControl(null),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit() {}
}
