import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  CopyInputDirective,
  SecretKeyGeneratorDirective,
  TrimInputDirective,
} from '@shared/directives';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-cp-directives',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CopyInputDirective,
    NgxMaskDirective,
    JsonPipe,
    SecretKeyGeneratorDirective,
    TrimInputDirective,
  ],
  providers: [provideNgxMask()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h2>ngx-mask</h2>
    <form [formGroup]="form" class="grid grid-cols-12">
      <mat-form-field class="col-span-full">
        <mat-label>Amount</mat-label>
        <input
          appTrimInput
          matInput
          placeholder="Enter you value"
          appCopyInput
          formControlName="amount"
          mask="separator.10"
          thousandSeparator=","
        />
        <button mat-icon-button type="button" matSuffix>
          <span class="fa-regular fa-copy text-secondary-60 fa-2xs"></span>
        </button>
        <mat-hint>Help Text</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-span-full">
        <mat-label>UUID generator</mat-label>
        <input appTrimInput matInput formControlName="uuid" />
        <button
          mat-icon-button
          type="button"
          matSuffix
          appSecretKeyGenerator
          (onSecretKeyGenerated)="onSecretKeyGenerated($event)"
        >
          <span class="fa-regular fa-circle-plus text-secondary-60 fa-2xs"></span>
        </button>
      </mat-form-field>
    </form>
    <h4>Form Value</h4>
    {{ form.value | json }}
  `,
  styles: ``,
})
export class CpDirectivesComponent {
  form = new FormGroup({
    amount: new FormControl(''),
    uuid: new FormControl(''),
  });

  onSecretKeyGenerated(secretKey: string) {
    // Update the form field with the generated key
    this.form.get('uuid')?.setValue(secretKey);
  }
}
