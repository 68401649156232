import { Component, inject } from '@angular/core';
import { ROUTES } from '@core/constants';
import { HttpService } from '@core/services';
import { LoadingComponent } from '@shared/components';
import { delay } from 'rxjs';

@Component({
  selector: 'app-cp-loading',
  imports: [LoadingComponent],
  template: ` <app-loading [loading]="loadingState"> Content After Loading...</app-loading> `,
  styles: ``,
})
export class CpLoadingComponent {
  loadingState: boolean;
  readonly httpService = inject(HttpService);

  constructor() {
    // this is a mock request call function, currently it is responding 200.
    const url = ROUTES.configuration.KYCProviders.retrieveKYCDetails(1);
    this.loadingState = true;
    this.httpService
      .get(url)
      .pipe(delay(5000))
      .subscribe({
        next: () => (this.loadingState = false),
        error: () => {},
      });
  }
}
