import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatChip } from '@angular/material/chips';
import { CryptoProviderDropDown } from '@core/models';
import { MultiSelectorComponent } from '@shared/components';
import { ValidSubmitDirective } from '@shared/directives';

@Component({
  selector: 'app-cp-multi-selector',
  imports: [MultiSelectorComponent, ReactiveFormsModule, ValidSubmitDirective, MatChip],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form class="mt-10" [formGroup]="form" appValidSubmit (onSubmit)="onSubmit()">
      <app-multi-selector controlName="multiSelector1" label="Multi Selector 1" [list]="val">
        <ng-template #chipTemplate let-item>
          <span class="Body-2">{{ item.provider.text }}</span>
          @if (!item.isPublished) {
            <span class="Body-2 text-secondary-70"> (Unpublished)</span>
          }
        </ng-template>

        <ng-template #optionTemplate let-items>
          @if (!items.isPublished) {
            <mat-chip class="chip option-chip">Unpublished</mat-chip>
          }
        </ng-template>
      </app-multi-selector>

      <button class="full-width">Submit</button>
    </form>
  `,
  styles: `
    .chip {
      border: 1px solid var(--c-secondary-70);
      --mdc-chip-label-text-color: var(--c-secondary-70) !important;
      background-color: transparent !important;
      height: 1.5rem;
    }
  `,
})
export class CpMultiSelectorComponent implements OnInit {
  form!: FormGroup;

  val: CryptoProviderDropDown[] = [
    {
      id: 1,
      provider: {
        value: 1,
        text: 'ChainUp1',
        description: 'ChainUp11',
      },
      isPublished: true,
      selected: true,
    },
    {
      id: 3,
      provider: {
        value: 1,
        text: 'ChainUp3',
        description: 'ChainUp33',
      },
      isPublished: false,
      selected: false,
    },
    {
      id: 4,
      provider: {
        value: 1,
        text: 'ChainUp4',
        description: 'ChainUp44',
      },
      isPublished: false,
      selected: false,
    },
    {
      id: 5,
      provider: {
        value: 1,
        text: 'ChainUp5',
        description: 'ChainUp55',
      },
      isPublished: true,
      selected: true,
    },
    {
      id: 6,
      provider: {
        value: 1,
        text: 'ChainUp6',
        description: 'ChainUp66',
      },
      isPublished: false,
      selected: false,
    },
    {
      id: 7,
      provider: {
        value: 1,
        text: 'ChainUp7',
        description: 'ChainUp77',
      },
      isPublished: true,
      selected: false,
    },
  ];

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.form = new FormGroup({
      multiSelector1: new FormControl(
        this.val.filter((item) => item.selected),
        [Validators.required],
      ),
    });
  }

  onSubmit() {
    console.log(this.form.value);
  }
}
